// Chakra Imports
import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

// API Imports
import { resourceLabellerRequest } from "../../../services/resourceTemplateService";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Constant Imports
import { defaultThemeColor, greyBgColor } from "../../../constants";
import { AiOutlinePlus } from "react-icons/ai";

const suggestedPrompts = [
  "Describe the image accurately in one sentence.",
  "Describe the camera angle of this image in one sentence.",
  "Describe the overall structural and architectural styles present in this image in one sentence.",
  "Describe the functional uses or purposes for the objects in this image in one sentence.",
  "Describe the most prominent or important object or element in this image in one sentence.",
  "Describe the textures and materials used in this image in one sentence.",
  "Describe the aesthetic decorations and details present in this image in one sentence.",
  "Describe the overall color palette of this image in one sentence.",
  "Describe the light sources in this image in one sentence.",
  "Describe the aesthetic moods this image was designed to invoke in one sentence.",
];

function BulkLabelerPanel(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [labelerPrompts, setLabelerPrompts] = useState([suggestedPrompts[0]]);
  const resourceId = props?.resourceId?.length ? props.resourceId : "";
  const folderKey = props?.folderKey?.length ? props.folderKey : "/";
  const totalRecords = props?.totalRecords ? props?.totalRecords : 0;

  function renderFolderName(folderName) {
    if (folderName === "/") {
      return "User Uploaded Images";
    } else if (folderName === "output/") {
      return "Corbu Generated Images";
    } else {
      return folderName;
    }
  }

  // Function to handle checkbox change
  const addSuggestedPrompt = (prompt) => {
    setLabelerPrompts((prevLabelerPrompts) => [...prevLabelerPrompts, prompt]);
  };

  const SubmitLabelerRequest = () => {
    if (resourceId && folderKey.length > 0) {
      let labeler_json = {
        model_sub_name: "caption",
        prompts: labelerPrompts,
        max_new_tokens: 512,
        do_sample: false,
      };
      if (folderKey.length > 0 && folderKey !== "/") {
        labeler_json.include_dirs = [folderKey];
      }
      labeler_json.exclude_dirs =
        folderKey === "output/"
          ? ["annotations/"]
          : ["annotations/", "output/"];
      let objBody = {
        resource_uuid: resourceId,
        labeler_json: JSON.stringify(labeler_json),
      };
      isLoading(true);
      resourceLabellerRequest(objBody)
        .then((res) => {
          if (res?.data?.length > 0 && res?.result) {
            toast(
              toastFunctionToaster("Labeller Started successfully..", "success")
            );
            isLoading(false);
          } else {
            toast(toastFunctionToaster(res?.data[0]?.error, "error"));
            isLoading(false);
          }
        })
        .catch((err) => {
          toast(
            toastFunctionToaster("Unable to start image labelling.", "error")
          );
          isLoading(false);
        });
    }
  };

  const renderLabelingController = () => {
    return (
      <VStack p={1}>
        <Flex w={"100%"}>
          <HStack w={"100%"}>
            <Text color={defaultThemeColor} fontWeight={"600"} fontSize={"sm"}>
              Selected Folder:
            </Text>
            <Text color={"#fff"} fontSize={"sm"}>
              {renderFolderName(folderKey)}
            </Text>
          </HStack>
          <HStack w={"100%"}>
            <Text color={defaultThemeColor} fontWeight={"600"} fontSize={"sm"}>
              Total Images:
            </Text>
            <Text color={"#fff"} fontSize={"sm"}>
              {totalRecords}
            </Text>
          </HStack>
        </Flex>
        <Text color={defaultThemeColor} textAlign={"center"} fontWeight={"600"}>
          Label Prompts
        </Text>
        <Box w={"100%"}>
          <Text fontSize={"xs"} mb={1} color={"#fff"} textAlign={"center"}>
            Provide additional prompts here for the labeller to consider while
            generating labels.
          </Text>
          <TagsInput
            maxTags={10}
            w={"100%"}
            h={"100%"}
            value={labelerPrompts}
            onChange={(tags) => {
              setLabelerPrompts(tags);
            }}
            inputProps={{
              placeholder:
                'Input a new prompt here and press "Enter" to add to the list...',
            }}
            isDisabled={loading ? true : false}
          />
        </Box>
        <Accordion allowToggle border={"0px"} borderRadius={"0px"} w="100%">
          <AccordionItem>
            <AccordionButton justifyContent={"space-between"}>
              <Text
                as="span"
                color={defaultThemeColor}
                textAlign={"center"}
                fontWeight={"600"}
              >
                Suggested Prompts
              </Text>
              <AccordionIcon color={defaultThemeColor} />
            </AccordionButton>
            <AccordionPanel justifyContent={"space-between"}>
              <VStack alignItems={"left"}>
                {suggestedPrompts
                  .filter(
                    (prompt) => !labelerPrompts.some((obj) => obj === prompt)
                  )
                  .map((prompt, index) => (
                    <Button
                      size="xs"
                      key={index}
                      colorScheme="blue"
                      py={1}
                      leftIcon={<AiOutlinePlus />}
                      onClick={(e) => {
                        addSuggestedPrompt(prompt);
                      }}
                    >
                      {prompt}
                    </Button>
                  ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    );
  };

  // Todo:Reset Fields
  const onCloseModal = () => {
    onClose();
    setLabelerPrompts([suggestedPrompts[0]]);
  };

  return (
    <Box>
      <Button size="sm" ml={2} onClick={onOpen}>
        {" "}
        Image Labelling{" "}
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onCloseModal();
        }}
        finalFocusRef={btnRef}
        size={props?.drawerSize ? props?.drawerSize : "lg"}
      >
        <DrawerOverlay />
        <DrawerContent bg={greyBgColor} borderWidth={"0px"}>
          <DrawerCloseButton color="#fff" />
          <DrawerHeader borderBottomWidth={"1px"} borderColor={greyBgColor}>
            <Text color={"#fff"} fontWeight={"500"} fontSize={"18px"}>
              Automatic Image Captioning and Labelling
            </Text>
          </DrawerHeader>

          <DrawerBody>
            {totalRecords === 0 ? (
              <Box w={"100%"} pl={2}>
                <Text fontSize={"md"} color="#fff">
                  Please upload images to this folder before generating labels.
                </Text>
              </Box>
            ) : (
              renderLabelingController()
            )}
          </DrawerBody>

          <DrawerFooter borderTopWidth={"1px"} borderColor={greyBgColor}>
            <VStack w="100%">
              <Flex
                justifyContent="space-between"
                alignItems={"center"}
                w="100%"
              >
                <Button
                  onClick={() => SubmitLabelerRequest()}
                  size={"sm"}
                  colorScheme={"green"}
                  isDisabled={
                    !(resourceId && folderKey.length > 0) ||
                    loading ||
                    labelerPrompts.length === 0
                  }
                  isLoading={loading}
                >
                  Generate Labels
                </Button>
                <Button
                  onClick={(e) => {
                    setLabelerPrompts([]);
                  }}
                  size={"sm"}
                  colorScheme={"red"}
                  isDisabled={
                    !(resourceId && folderKey.length > 0) ||
                    labelerPrompts.length === 0
                  }
                >
                  Clear Prompts
                </Button>
              </Flex>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default BulkLabelerPanel;
