// Chakra Imports
import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Text,
  Box,
  Flex,
  Heading,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor } from "../../../../constants/index";
import AddBusinessForm from "./AddBusinessForm";
import LandingBgImage from "../../../../image/LandingPageImg/landingbg.png";

function SetupBusinessInfo() {
  const navigate = useNavigate();

  // Todo:Update Business Modal And Details
  const handleBusinessDetails = (data) => {
    if (data) {
      navigate("/business/projects");
    }
  };

  return (
    <Box bg="black" color="#fff">
      <Box
        bgImage={LandingBgImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100vh"
        p="10"
      >
        <Flex justifyContent="end" mt="2%">
          <Box bg="#141718" borderRadius={"20px"} p="20px" w="40%">
            <Heading mb="4" fontSize={"24px"}>
              Setup Business Details
            </Heading>
            <Box bg="#1c181e" mt="3" p="10px" borderRadius={"10px"}>
              <Text mb="5px" fontSize={16} color={defaultThemeColor}>
                You haven't added your business details yet. Please add your
                business details here and click "Add Business Info".
              </Text>
            </Box>
            <AddBusinessForm
              selectedDetailsType={"basic_info"}
              updateBusinessDetails={handleBusinessDetails}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default SetupBusinessInfo;
