import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "../components/Login";
import Dashboard from "../components/Dashboard";
import ProtectedLayout from "./ProtectedLayout";
import UserProfile from "../components/UserProfile/index";
import LoginHome from "../components/LoginHome/LoginHome";
import CorbuTeam from "../components/Admin/CorbuTeam/CorbuTeam";
import AdminProtectedLayout from "../routes/AdminProtectedLayout";
import ScrollToTop from "../sharedComponents/generic/ScrollToTop";
import UserInfo from "../components/UserProfile/UserInfo/UserInfo";
import PageNotFound from "../components/pageNotFound/PageNotFound";
import HomeDashboard from "../components/Home/Dashboard/HomeDashboard";
import BusinessProtectedLayout from "../routes/BusinessProtectedLayout";
import AdminBusiness from "../components/Admin/AdminBusiness/AdminBusiness";
import BUManagement from "../components/Business/BUManagement/BUManagement";

// Business and Business User Management
import SetupBusinessInfo from "../components/Business/Settings/BusinessInfo/SetupBusinessInfo"
import RegisterBusinessUser from "../components/Register/RegisterBusinessUser";
import InviteBusinessUser from "../components/Business/BUManagement/InviteBusinessUser/InviteBusinessUser";
import BusinessUsersList from "../components/Admin/AdminBusiness/BusinessUsersList/BusinessUsersList";
import BusinessUsersListMgmt from "../components/Business/BUManagement/BusinessUsersMgmt/BusinessUsersMgmt";
import BusinessAnalysis from "../components/Business/Analysis/BusinessAnalysis";
import DepartmentList from "../components/Business/Department/DepartmentList";
import ProjectManager from "../components/Business/ProjectManagement/ProjectManager";
import ResourcesListing from "../components/Business/Resources/ResourcesList";
import ResourcesView from "../components/Business/Resources/ResourceView";
import SharedWorkflows from "../components/Business/Shared/SharedWorkflows";

// Admin Sections
import AdminDashboards from "../components/Admin/Dashboards/AdminDashboards";
import TeamProtectedLayout from "./TeamProtectedLayout";
import AddTeamMember from "../components/Admin/CorbuTeam/AddTeamMember/AddTeamMember";
import CorbuTeamList from "../components/Admin/CorbuTeam/CorbuTeamList/CorbuTeamList";
import BusinessList from "../components/Admin/AdminBusiness/BusinessList/BusinessList";
import UpdateInformation from "../components/UserProfile/UpdateInfo/UpdateInformation";
import ChangePasswordProfile from "../components/UserProfile/ChangePassword/ChangePassword";
import ChangeDefaultPassword from "../components/ChangeDefaultPassword/ChangeDefaultPassword";
import DisabledTeamMember from "../components/Admin/CorbuTeam/DisabledTeamMember/DisabledTeamMember";

// default layout routes
import AddForm from "../components/defaultLayout/addForm/AddForm";
import Content from "../components/defaultLayout/contetnt/Content";
import DefaultLayout from "../components/defaultLayout/DefaultLayout";
import DefaultDashboard from "../components/defaultLayout/dashboard/DefaultDashboard";
import Workspace from "../components/defaultLayout/workspace/Workspace";
import DefaultTable from "../components/defaultLayout/table/DefaultTable";
import EmptyPage from "../components/defaultLayout/emptyPage/EmptyPage";
import DesignProject from "../components/Business/ProjectManagement/DesignProjects/DesignProject";
import Settings from "../components/Business/Settings/Settings";
import Playground from "../components/Business/BUPlayground/Playground";
import PromptLibrary from "../components/Business/Prompt/PromptLibrary";
import ResourceFolderImage from "../components/Business/Resources/ResourceFolderImage";
import AdvanceSettings from "../components/Business/Resources/AdvanceSettings";
import BusinessDashboard from "../components/Business/Dashboard/BusinessDashboard";
import PiplineList from "../components/Business/Pipeline/PiplineList";
import SharedSpace from "../components/SharedSpace/SharedSpace";
import UserSharedSpace from "../components/SharedSpace/UserSharedSpace";

export default function RoutesComp() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<LoginHome />} />
        <Route path="/home" element={<LoginHome />} />
        <Route index path="/login" element={<LoginPage />} />
        <Route
          path="/change_default_password"
          element={<ChangeDefaultPassword />}
        />
        <Route
          path="/business/RegisterUser"
          element={<RegisterBusinessUser />}
        />
        <Route path="/sharedSpace/" element={<SharedSpace />} />
        <Route element={<ProtectedLayout />}>
          <Route path="/setup_business_info" element={<SetupBusinessInfo />} />
          <Route path="/" element={<Dashboard></Dashboard>}>
            <Route path="dashboard" element={<HomeDashboard />} />
            <Route path="default" element={<DefaultLayout />}>
              <Route path="default-screen-1" element={<Content />} />
              <Route path="default-screen-2" element={<Workspace />} />
              <Route path="default-dashboard" element={<DefaultDashboard />} />
              <Route path="default-table" element={<DefaultTable />} />
              <Route path="default-form" element={<AddForm />} />
              <Route path="default-empty-page" element={<EmptyPage />} />
            </Route>

            <Route path="personal" element={<UserProfile />}>
              <Route path="Info" element={<UserInfo />} />
              <Route path="Update" element={<UpdateInformation />} />
              <Route path="UpdateAccess" element={<ChangePasswordProfile />} />
            </Route>

            <Route element={<AdminProtectedLayout />}>
              <Route path="admin">
                <Route path="dashboards" element={<AdminDashboards />} />
                <Route path="corbuteam" element={<CorbuTeam />}>
                  <Route path="AllTeamMembers" element={<CorbuTeamList />} />
                  <Route
                    path="AllTeamMembersStatus"
                    element={<DisabledTeamMember />}
                  />
                  <Route path="AddNewTeamMember" element={<AddTeamMember />} />
                </Route>
                <Route path="business" element={<AdminBusiness />}>
                  <Route path="AllBusinesses" element={<BusinessList />} />
                  <Route
                    path="AllBusinessUsers"
                    element={<BusinessUsersList />}
                  />
                </Route>
              </Route>
            </Route>

            <Route element={<BusinessProtectedLayout />}>
              <Route path="business">
                <Route path="dashboard" element={<BusinessDashboard />} />
                <Route path="designProject" element={<DesignProject />} />
                <Route path="playground" element={<Playground />} />
                <Route path="playgroundDesign" element={<DesignProject />} />
                {/* Prompt Library Routes */}
                <Route path="promptLibrary" element={<PromptLibrary />} />
                <Route path="sharedworkflows" element={<SharedWorkflows />} />
                <Route path="projects" element={<ProjectManager />} />
                <Route
                  path="projects/userSpace/:id"
                  element={<UserSharedSpace />}
                />
                <Route path="settings" element={<Settings />} />
                <Route path="resourceslist" element={<ResourcesListing />} />
                <Route
                  path="resourceslist/resources"
                  element={<ResourcesView />}
                />
                <Route
                  path="resourceslist/resources/advance"
                  element={<AdvanceSettings />}
                />
                <Route
                  path="resourceslist/resources/folderImage"
                  element={<ResourceFolderImage />}
                />
                <Route path="analysis" element={<BusinessAnalysis />} />
                <Route path="bizmanagement" element={<BUManagement />}>
                  {/* <Route path="BizTeamDetails" element={<WorkingComponent />} /> */}
                  <Route
                    path="BizUsersListMgmt"
                    element={<BusinessUsersListMgmt />}
                  />
                  <Route
                    path="InviteBusinessUser"
                    element={<InviteBusinessUser />}
                  />
                </Route>
                <Route
                  path="departments"
                  element={<DepartmentList></DepartmentList>}
                ></Route>
                {/* Pipeline Route */}
                <Route path="pipeline" element={<PiplineList />} />
              </Route>
            </Route>

            {/* <Route element={<TeamProtectedLayout />}>
              <Route path="TeamMember">
                <Route path="client" element={<Client />}>
                  <Route path="client_info" element={<ClientList />}></Route>
                  <Route
                    path="active_jobs"
                    element={<WorkingComponent />}
                  ></Route>
                  <Route
                    path="closed_jobs"
                    element={<WorkingComponent />}
                  ></Route>
                </Route>
                <Route path="settings" />
              </Route>
            </Route> */}
          </Route>
        </Route>

        <Route path="/default_form" element={<AddForm />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
